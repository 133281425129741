<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-row class="bradcrumb">
            <router-link :to="{name: 'home' }" class="root">
                Dashboard
            </router-link>
            <span class="child">/ Import Books </span>
        </v-row>
        <br />
         <v-dialog persistent v-model="isLoading">
            <v-row justify="center">
            <v-card width="400" height="100">
              <center class="pa-5">
               Editng Book data.<br />
                Please Wait It will Take Time..
              </center>
            </v-card>
            </v-row>
          </v-dialog>
        <v-card class="card">
            <v-card-title class="heading justify-center">Import Books</v-card-title>
            <v-card-text>
                <div class="add-section">
                    <v-row class="justify-content-between set-btn">
                        <div><a href="https://library-public.s3.ap-south-1.amazonaws.com/sample_csv/Issue_Book_import_format.csv">
                            <v-btn class="mb-2 button"> <v-icon left dark>mdi-cloud-download</v-icon>Export CSV Template</v-btn></a>
                        </div>
                    </v-row>
                    <p class="file-warn">NOTE: *File should be in CSV format.</p>

                    <v-row class="justify-content-center">
                        <v-col lg="5" sm="12" md="5" class="import-box">
                            <label class="label">IMPORT CSV</label>
                            <v-file-input
                                dense
                                v-model="file"
                                :rules="[v => !!v || 'File required']"
                                persistent-hint
                                color="rgb(1, 127, 152)"
                                label="Choose File"
                                accept=".csv"
                                prepend-icon="mdi-paperclip"
                                :error-messages="!fileStatus?'File Required':''"
                                outlined
                                show-size
                            >
                            </v-file-input>
                            <v-btn :loading="output_load" @click="importCsv" class="button">
                                Import
                                <v-icon right>mdi-cloud-upload</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>

                <template>
                    <h5 v-if="failList !== null" class="mt-4" style="text-align: center; color: red;">Failed Record</h5>
                    <v-simple-table v-if="failList !== null">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">PRN No./Employee Code</th>
                                    <th class="text-left">Accession No.</th>
                                    <th class="text-left">Borrowed Date</th>
                                    <th class="text-left">Due Date </th>
                                    <th class="text-left">Reason</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in failList" :key="item.name">
                                    <td>{{ item.Member_Code }}</td>
                                    <td>{{ item.accession_No }}</td>
                                    <td>{{ item.Borrowed_Date }}</td>
                                    <td>{{ item.Due_Date }}</td>
                                    <td style="color: red;">{{ item.reason }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </template>


            </v-card-text>
        </v-card>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
    import axios from "axios";
    import "@/assets/css/color.css"; 
    export default {
        data: () => ({
            valid: false,
            overlay: false,
            failList: [],
            snackbar_msg: "",
            color: "",
            snackbar: false,
            search: "",
            file: null,
            dialog: false,
            fileStatus: true,
             isLoading:false,
        }),

        methods: {
            importCsv() {
                let formData = new FormData();
                if (this.file !== null) {
                   if (!(this.file.name.toLowerCase().includes(".csv") || this.file.name.toLowerCase().includes(".CSV"))) {
                       this.showSnackbar("red", "File must be in CSV format Only.!");
                   } else{
                        this.fileStatus = true;
                        formData.append("file", this.file);
this.isLoading=true;
                        axios
                            .post("ImportData/importIssueBook1", formData, {
                                headers: {
                                    "Content-Type": "multipart/form-data",
                                },
                            })
                            .then((res) => {
                               
                                this.failList = res.data.failList;
                                if (res.data.status == "200") {
                                     this.isLoading=false;
                                    this.showSnackbar("#4caf50", "Book Issue Data imported successfully...");
                                }
                                else{
                                     this.isLoading=false;
                                }
                                this.file = null;
                            })
                            .catch(function () {
                                this.showSnackbar("#b71c1c", "Something went wrong..."); 
                                  this.isLoading=false;
                            })
                            .finally(() => {
                                  this.isLoading=false;
                                this.output_load = false;
                            });
                    }
                } else {
                    this.fileStatus = false;
                    this.showSnackbar("#b71c1c", "Please select file");
                }
            },
           
            showSnackbar(clr, msg) {
                this.snackbar = true;
                this.color = clr;
                this.snackbar_msg = msg;
            },

            close() {
                this.dialog = false;
            },
        },
    };
</script>
<style scoped>
    .v-text-field {
        padding-top: 0px;
        margin-top: 0px;
    }
    .v-icon-size {
        font-size: 20px;
    }
    .edit-avatar {
        transition: 0.3s;
        border: 1px solid #b0bec5;
    }
    .edit-avatar:hover {
        background-color: #b0bec5;
    }
    .edit-v-icon {
        padding-left: 6px;
        font-size: 15px;
    }
    .edit-v-icon:hover {
        color: white;
    }
    .fields {
        padding: 0px !important;
    }
    .add-section {
        display: block;
    }
    .import-box {
        margin: 1rem 1rem 2rem 1rem;
        border: 2px solid;
        padding: 1rem 2rem 1rem 2rem;
        text-align: center;
        background: #ffffffb5;
    }
    .label {
        color: black;
        font-size: 16px;
        font-weight: 400;
    }
    .button {
        background: white !important;
    }
    .set-btn {
        margin: 0rem 1rem 0rem 1rem;
        padding-top: 1rem;
    }
    .button:hover {
        color: white;
        background: black !important;
    }
    .file-warn {
        font-style: italic;
        color: red;
        text-align: center;
        margin: 0;
    }
</style>
